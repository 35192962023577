.heading {
  font-weight: 700;
  font-size: 56px;
  line-height: 76px;
  text-align: left;
  color: #0E009D;
}

.contact-info {
  margin-top: 59px;
}

.h-para {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  color: #312948B2;
  text-align: left;
}

.btn-message {
  background-color: #4D3EE9;
  color: white;
  border-radius: 6px;
  height: 61px;
  font-weight: 600;
  font-size: 18px;
}

.btn-submit {
  background-color: #4D3EE9;
  color: white;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  height: 61px;
  margin-top: 18px;
}

.btn-message:hover,
.btn-submit:hover {
  color: white;
  background-color: #0E009D;
}

.form-container {
  margin: 60px auto;
  max-width: 100%;
}

.bg-beige {
  background-color: #F2E7FE;
  padding: 20px;
  border-radius: 8px;
}

.form-controll {
  width: 100%;
  height: 61px;
  color: #9B9B9BCC;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.form-label {
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
}

.contact-details-para {
  margin: 28px 0;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  color: #0E009D;
}

.contact-details-para a {
  color: #000000E5;
}

.contact-details {
  margin-top: 30px;
}

.contactsmall {
  display: flex;
  
  gap: 20px;
}

/* Media Queries for Responsiveness */
@media (max-width: 1100px) {
  .heading {
      text-align: center;
      font-size: 48px;
  }

  .contactsmall {
      flex-direction: column;
      align-items: center;
  }
}

@media (max-width: 768px) {
  .heading {
      font-size: 36px;
  }

  .h-para {
      font-size: 18px;
      text-align: center;
  }

  .form-container {
      margin: 20px auto;
      width: 90%;
  }

  .form-controll {
      font-size: 16px;
  }

  .btn-message,
  .btn-submit {
      font-size: 16px;
      height: 50px;
  }
}

@media (max-width: 576px) {
  .heading {
      font-size: 28px;
      line-height: 1.2;
      text-align: center;
  }

  .h-para {
      font-size: 18px;
      line-height: 1.5;
      text-align: center;
  }

  .btn-message {
      
      height: 40px;
      font-size: 14px;
  }

  .btn-submit {
      width: 100%;
      height: 40px;
      font-size: 14px;
  }

  .form-controll {
      font-size: 14px;
      height: 40px;
  }

  .bg-beige {
      padding: 15px;
  }

  .contact-info,
  .contact-details {
      text-align: center;
      margin: 10px 0;
  }
}
