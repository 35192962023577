.contact-us-container {
  display: flex;
  justify-content: space-around;
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    outline: 1px solid #0E009D;
    background-color: #F2E7FE;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .promo-title {
    font-size: 16px;
    color: #0E009D;
  }
  
  .promo-btn {
    width: 192px;
    height: 52px;
    background-color: white;
    border: none;
    padding: 10px 20px;
    color: #0E009D;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .promo-btn:hover {
    background-color: #357abd;
  }

  /*  */
  /* General Section Styling */
.contact-us-section {
  padding: 50px 20px;
  /* background-color: #f9f5ff; */
  text-align: center;
}

.contactcontainer {
  max-width: 1000px;
  margin: 0 auto;
}

/* Headings */
.contact-heading {
  font-size: 1.2rem;
  color: #0E009D;
  margin-bottom: 10px;
  font-weight: bold;
}

.contact-subheading {
  font-size: 2rem;
  color: #0E009D;
  margin-bottom: 10px;
  font-weight: 700;
}

.contact-description {
  font-size: 1rem;
  color: #5a5a5a;
  margin-bottom: 30px;
  line-height: 1.6;
}

/* Form Styling */
.contact-form {
  background-color: #F2E7FE;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  flex: 1;
  display: flex;
  flex-direction: column;
}

label {
  font-weight: 600;
  text-align: left;
  font-size: 22px;
  color: #312948E5;
  margin-bottom: 8px;
}

.form-control {
  padding: 10px;
  font-size: 1rem;
  border: 1.5px solid #6C677D80 ;
  border-radius: 4px;
}

textarea.form-control {
  resize: none;
}

/* Button Styling */
.submit-button {
  display: inline-block;
  padding: 12px 20px;
  background-color: #4D3EE9;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 20px;
}

.submit-button:hover {
  background-color: #5a54e3;
}

/* Responsive Design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}
