/* General Section Styles */
.pricing-section {
    padding: 50px 0;
    color: #333;
  }
  
  .offer-banner {
    background-color: #e0e7ff;
    color: #0E009D;
    font-weight: bold;
    padding: 5px 15px;
    border-radius: 90px;
    outline: 1px solid #0E009D;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .pricing-title {
    
    font-size: 40px;
    font-weight: bold;
    color: #2c2c8c;
  }
  
  .pricing-subtitle {
    font-size: 59px;
    font-weight: bold;
    color: #4D3EE9;
  }
  
  .pricing-description {
    
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 40px;
    
  }
  
  /* Pricing Card Styles */
  .pricing-card {
    background-color: #F2E7FE;
    border-radius: 15px;
    padding: 30px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .pro-plan {
    background-color: #2c2c8c;
    color: #fff;
  }
  
  .slots-limited {
    justify-content: left;
    font-size: 0.9rem;
    color: #6c63ff;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .plan-name {
    text-align: left;
    font-size: 1.8rem;
    font-weight: bold;
  }
  
  .plan-price {
    text-align: left;
    font-size: 2.2rem;
    font-weight: bold;
    margin: 10px 0;
  }
  
  .plan-price span {
    text-align: left;
    font-size: 1rem;
  }
  
  .payment-info {
    text-align: left;
    font-size: 0.9rem;
  }
  
  .plan-description {
    border-bottom: 1px solid ;
    font-size: 0.9rem;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .plan-features {
    text-align: left;
    list-style: none;
    padding: 0;
    margin: 0 0 20px;
  }
  
  .plan-features li {
    font-size: 0.9rem;
    margin-bottom: 10px;
    display: flex;
  
  }
 /* Tick Icon Styling */
.tick-icon {
    color: #6c63ff;
    margin-right: 8px;
    font-size: 1rem;
    vertical-align: middle;
  }
  
  /* Rest of the CSS remains unchanged from the previous implementation */
  
  .choose-plan-btn {
    width: 100%;
    background-color: #4D3EE9;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
  }
  .probtn:hover{
    background-color: #3528c3;
    color: white;
  }
  .probtn{
    background-color: white;
    color: black;
  }
  .choose-plan-btn:hover {
    background-color: #2c2c8c;
  }
  
  .or-call {
    font-size: 0.9rem;
    margin-top: 10px;
  }
  