/* JobOpenings.css */
.job-openings-container {
    width: 80%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #0E009D;
    text-align: center;
    /* height: 222px; */
  }
  
.job-heading {
    text-align: center;
    font-size: 56px;
    font-weight: 700;
    color: #0E009D;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  
  .job-nav {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .job-nav button {
    background: none;
    border: none;
    font-size: 1em;
    color: #312948B2;
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .job-nav button:hover {
    text-decoration: underline;
    color: #4D3EE9;
  }
  
  .job-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .job-card {
    background-color: #F2E7FE;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #ddd;
    text-align: left;
    margin-bottom: 20px;
  }
  
  .white-background {
    background-color: #ffffff; /* Set background color to white for alternate cards */
  }
  
  
  .job-card h2 {
    font-size: 20px;
    font-weight: 700;
    color: #1d0a42;
    margin-bottom: 5px;
  }
  
  .job-card p {
    margin: 5px 0;
    color: #303030;
  }
  
  .job-tags {
    margin-top: 10px;
  }

  .jobs-description{
    font-weight: 500;
    font-size: 16px;
    color: #312948B2;
  }
  
  .job-tag {
    background-color: #4D3EE9;
    color: #f9f4ec;
    padding: 5px 10px;
    margin-right: 5px;
    border-radius: 3px;
    font-size: 0.9em;
    display: inline-block;
  }
  
  .apply-button {
    background-color: white;
    color: #4D3EE9;
    padding: 10px 20px;
    border-color: #4D3EE9 ;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1em;
    margin-top: 15px;
  }
  
  .apply-button:hover {
    background-color: #4D3EE9;
    color: white;
  }


  .Explore-button{
    width: 192px;
    height: 60px;
    color:  white;
    background-color: #4D3EE9;
    margin-bottom: 20px;
  }

  .Explore-button:hover{
    background-color: #0E009D;
    color: white;
  }










  /* Responsive Styles for Small Screens */
@media (max-width: 768px) {
  .job-openings-container {
    width: 90%;
    font-size: 0.9em;
  }

  .job-heading {
    font-size: 36px;
    margin-top: 20px;
  }

  .job-nav {
    flex-wrap: wrap;
    gap: 10px;
  }

  .job-nav button {
    font-size: 0.9em;
    padding: 5px;
  }

  .job-list {
    gap: 10px;
  }

  .job-card {
    padding: 15px;
    font-size: 0.9em;
  }

  .job-card h2 {
    font-size: 18px;
  }

  .job-card p {
    font-size: 14px;
  }

  .job-tag {
    font-size: 0.8em;
    padding: 4px 8px;
  }

  .apply-button {
    padding: 8px 15px;
    font-size: 0.9em;
  }

  .Explore-button {
    width: 150px;
    height: 50px;
    font-size: 0.9em;
  }
}
